.graph {
  display: flex;
  padding: 0 20px;

  canvas {
    width: 100% !important;
    height: 100% !important;
    max-width: 500px;
  }
}
