.nav {
  position: fixed;
  z-index: 2;
  width: 100%;
  height: auto;

  &__logo {
    display: flex;
    width: 50px;
    height: 50px;

    svg {
      width: 30px;
      height: 30px;
      padding: 10px;
    }
  }

  @media only screen and (max-width: 600px) {
    &__logo svg {
      width: 28px;
      height: 28px;
    }
  }
}
