.home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  .scroll-container {
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  h2 {
    font-family: 'Gotham Black';
    font-weight: bold;
    font-size: 3rem;
    text-transform: uppercase;
    text-shadow: 0px 0px 0px rgb(6 6 6 / 20%);
    color: #fff;
    margin: 0;

    @media only screen and (max-width: 600px) {
      font-size: 2rem;
    }
  }

  h3 {
    font-family: 'Gotham Black';
    font-weight: bold;
    font-size: 3rem;
    text-transform: uppercase;
    text-shadow: 0px 0px 0px rgb(6 6 6 / 20%);
    color: #c0c0c0;
    margin: 0;

    @media only screen and (max-width: 600px) {
      font-size: 2rem;
    }
  }

  h4 {
    font-family: 'Gotham Black';
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    text-shadow: 0px 0px 0px rgb(6 6 6 / 20%);
    color: #c0c0c0;
    margin: 0;

    @media only screen and (max-width: 600px) {
      font-size: 0.6rem;
    }
  }

  p {
    font-family: 'Ocra';
    text-transform: uppercase;
    font-size: 1rem;
    color: #d1d1d1;
    line-height: 1.4;

    b {
      color: #fff;
    }
  }

  section {
    display: flex;
    height: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .section-image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 600px;
    width: auto;

    @media only screen and (max-width: 600px) {
      height: 300px;
    }
  }

  .video-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-image: linear-gradient(0.55turn, #b32c2cc2, #19093fd4);
  }

  video {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -2;
  }

  .section-content {
    display: flex;
    flex-direction: column;

    &__ten-domains {
      background: #0a0a0a;
    }

    &__graph {
      background: #ededed;

      h2 {
        color: #252525;
      }

      .section-title {
        margin-bottom: 60px;
      }
    }
  }

  .section-title {
    display: flex;
    flex-direction: column;
    max-width: 600px;

    &__hero {
      position: relative;
      z-index: 1;
    }

    @media only screen and (max-width: 700px) {
      max-width: 460px;
    }

    @media only screen and (max-width: 600px) {
      max-width: 380px;
    }
  }

  .legend {
    display: flex;

    &__item {
      padding: 0 20px;
      position: relative;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        display: inline-block;
        width: 40px;
        height: 14px;
        background-color: #dd588b;
        margin-right: 10px;
        border-radius: 3px;
      }

      &--month1::before {
        background-color: #6d618b;
      }

      p {
        color: #949494;
      }
    }
  }

  .coming-soon {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    p {
      cursor: pointer;
      margin: 0;
      color: #fff;
    }
  }
}
