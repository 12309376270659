.domain-items {
  display: flex;
  padding: 20px;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
  max-width: 900px;
  width: 100%;
  flex-wrap: wrap;
  user-select: none;

  @media only screen and (max-width: 600px) {
    padding: 0;
  }
}

.domain-item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
  max-width: 20%;
  width: 100%;
  cursor: pointer;

  svg {
    width: 40px;
    height: 40px;
  }

  &__image {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 10px;

    svg {
      width: 30px;
      height: 30px;
    }

    h3 {
      font-size: 0.6rem;
    }
  }
}
