.signup {
  max-width: 440px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  margin: 0 20px;

  #mc_embed_signup {
    background: none !important;
    width: 100% !important;
  }

  form {
    margin: 10px 0 0 0 !important;
  }

  .hide {
    visibility: hidden;
    position: absolute;
    left: -9999px;
  }

  .subscribe-containter {
    display: flex;
    width: 100%;
  }

  .email {
    color: black !important;
  }

  .email {
    height: 40px !important;
    padding: 0 10px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    display: flex !important;
    box-sizing: border-box !important;
    width: 100% !important;
  }

  .helper_text {
    background: none !important;
    color: #fff !important;
  }

  .button {
    margin: 0 !important;
    height: 42px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    background-color: black !important;
    display: flex !important;
    clear: initial !important;
    width: 110px !important;
    box-sizing: border-box !important;
    border-color: transparent !important;
    text-align: center !important;

    &:hover {
      background-color: #222 !important;
    }
  }

  .mc-field-group {
    display: flex !important;
    clear: initial !important;
    box-sizing: border-box !important;
    width: calc(100% - 110px) !important; // Adjust for any padding or border
  }

  &__form {
    display: flex;
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    &__form {
      flex-direction: column;
    }

    .mc-field-group {
      width: 100% !important; // Adjust for any padding or border
      padding: 0 !important;
    }

    .button,
    .email {
      border-radius: 3px !important;
      display: block !important;
      width: 100% !important;
      text-align: center !important;
      margin: 5px 0 !important;
    }
  }
}
